'use client'
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react'

// Import Swiper styles
import 'swiper/css'
import 'swiper/css/navigation'
import 'swiper/css/pagination'

// import required modules
import { Autoplay, Navigation, Pagination } from 'swiper'

import './HomeSlider.css'
import FullImageBanner from '../banners/FullImageBanner'
import Title from '../atoms/Title'
import Paragraph from '../atoms/Paragraph'
import { Button } from '../buttons/Button'

const HomeSlider = () => {
  return (
    <Swiper
      spaceBetween={0}
      centeredSlides={true}
      autoplay={{
        delay: 8000,
        disableOnInteraction: false
      }}
      loop={true}
      pagination={{
        clickable: true
      }}
      navigation={true}
      modules={[Autoplay, Pagination, Navigation]}
      className="homeSlider"
    >
      <SwiperSlide>
        <FullImageBanner
          imageUrl="/assets/secciones/cursos-y-certificaciones/PaginaDeCapacitacion-Banner-CursosYCertificaciones.webp"
          imageMobileUrl="/assets/secciones/cursos-y-certificaciones/PaginaDeCapacitacion-Banner-CursosYCertificaciones--movil.webp"
          imageClassName="bg-blue-dark-degree-banner"
          backgroundPosition="top center"
          bannerHeight="large"
          backgroundOpacity="rgba(0, 0, 0, 0.6)"
          title={
            <Title className="text-white smallTitle" spanColor="blue-light">
              <span>¡POR TIEMPO LIMITADO!</span>
            </Title>
          }
          subtitle={
            <Paragraph className="text-2xl">
              <span className="text-4xl white">
                Compre cualquier curso de nuestro catálogo y obtenga adicional un lugar para capacitarse en{' '}
                <strong>Fundamentos de Análisis de Impacto al Negocio basado en ISO 22317</strong>
              </span>
              <br />
              <br />
              <Button theme="primary-dark" href="/cursos-y-certificaciones">
                Ver catálogo de cursos
              </Button>
            </Paragraph>
          }
          contentAligment="center"
          contentClassNames="text-white"
          className="max-w-full overflow-hidden"
        />
      </SwiperSlide>

      <SwiperSlide>
        <FullImageBanner
          imageUrl="/assets/secciones/landings/servicios/implementacion-iso-iec-27001/banner-seguridad.jpg"
          imageMobileUrl="/assets/secciones/landings/servicios/implementacion-iso-iec-27001/banner-seguridad.jpg"
          imageClassName="bg-blue-dark-degree-banner"
          backgroundPosition="top center"
          bannerHeight="large"
          title={
            <Title className="text-white" spanColor="blue-light">
              <span>Cumpla con los requerimientos</span> de la norma <span>ISO/IEC 27001</span>
            </Title>
          }
          subtitle={
            <Paragraph className="text-2xl pt-5" spanColor="blue-light">
              Nuestros Consultores expertos le pueden ayudar
              <br />
              <br />
              <a className="blueBtn" href="/landing/servicios-de-consultoria/implementacion-iso-iec-27001">
                Más detalles aquí
              </a>
            </Paragraph>
          }
          contentAligment="center"
          contentClassNames="text-white"
          className="max-w-full overflow-hidden"
        />
      </SwiperSlide>

      <SwiperSlide>
        <FullImageBanner
          imageUrl="/assets/secciones/cursos-y-certificaciones/pilares/itil-cursosbanner-imagendefondo.webp"
          imageMobileUrl="/assets/secciones/cursos-y-certificaciones/pilares/itil-cursosbanner-movil-1.webp"
          imageClassName="bg-blue-dark-degree-banner"
          backgroundPosition="top center"
          bannerHeight="large"
          title={
            <Title className="text-white" spanColor="blue-light">
              <span>Evite que expire</span> su certificación{' '}
              <span>
                ITIL<sup>®</sup> 4
              </span>
            </Title>
          }
          subtitle={
            <Paragraph className="text-2xl pt-5" spanColor="blue-light">
              Conozca cómo renovarla y especializarse en el{' '}
              <strong>
                marco de ITIL<sup>®</sup>
              </strong>
              <br />
              <br />
              <Button theme="primary-dark" href="/cursos-y-certificaciones/itil">
                Más detalles aquí
              </Button>
            </Paragraph>
          }
          contentAligment="center"
          contentClassNames="text-white"
          className="max-w-full overflow-hidden"
        />
      </SwiperSlide>

      <SwiperSlide>
        <FullImageBanner
          imageUrl="/assets/secciones/cursos-y-certificaciones/isc2/banner-image-background-azul.webp"
          imageMobileUrl="/assets/secciones/cursos-y-certificaciones/isc2/banner-image-background-azul.webp"
          imageClassName="bg-blue-dark-degree-banner"
          backgroundPosition="top center"
          bannerHeight="large"
          title={
            <Title className="text-white" spanColor="blue-light">
              Certifíquese en <span>CC℠ Certified in Cybersecurity</span> de{' '}
              <span>
                ISC2<sup>®</sup>
              </span>
            </Title>
          }
          subtitle={
            <Paragraph className="text-2xl pt-5" spanColor="blue-light">
              Comience su carrera profesional en Ciberseguridad
              <br />
              <br />
              <a className="blueBtn" href="/landing/cursos-y-certificaciones/cc-certified-in-cybersecurity">
                Más detalles aquí
              </a>
            </Paragraph>
          }
          contentAligment="center"
          contentClassNames="text-white"
          className="max-w-full overflow-hidden"
        />
      </SwiperSlide>
    </Swiper>
  )
}

export default HomeSlider
