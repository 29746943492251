import { classNames } from '@/components/shared/classNames'
import styles from './Paragraph.module.scss'

interface Props {
  tag?: 'p' | 'span' | 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'label' | 'div' | 'figcaption'
  spanColor?:
    | 'none'
    | 'blue-darkest'
    | 'blue-dark'
    | 'blue-medium'
    | 'blue'
    | 'blue-light'
    | 'blue-lightest'
    | 'gray-darkest'
    | 'gray-dark'
    | 'gray'
    | 'gray-medium'
    | 'gray-light'
    | 'gray-lightest'
    | 'purple-dark'
    | 'purple-medium'
    | 'purple'
    | 'magenta'
    | 'green'
    | 'green-dark'
    | 'white'
    | 'black'
    | 'error'
  className?: string
  children?: React.ReactNode
  dangerouslySetInnerHTML?: {
    __html: string
  }
}

const Paragraph = ({ tag = 'p', className, spanColor = 'none', children, dangerouslySetInnerHTML }: Props) => {
  const Tag = tag
  return (
    <Tag
      className={classNames(styles.Paragraph, styles[`childspan-${spanColor}`], className)}
      dangerouslySetInnerHTML={dangerouslySetInnerHTML ? { __html: dangerouslySetInnerHTML.__html } : undefined}
    >
      {dangerouslySetInnerHTML ? null : children}
    </Tag>
  )
}

export default Paragraph
